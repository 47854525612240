<template>
  <ul class="grid t3">
    <li>
      <div class="form_wrap t2">
        <span class="">{{ title }}</span>
        <input
          class="wid120"
          type="text"
          placeholder="시작일"
          ref="startdp"
          v-model="sdate"
        />
        <div class="input_calendar inline">
          <input
            type="text"
            placeholder="종료일"
            @click.prevent="selRangePicker"
            v-model="edate"
          />
          <button
            class="tbl_icon calendar"
            @mousedown.stop.prevent
            @click.stop.prevent="toggleRangePicker"
          >
            달력
          </button>
        </div>
      </div>
    </li>
    <li v-show="isTermBtnShow('01')" class="btn_rdo">
      <input type="radio" value="01" :id="`${compId}-rdo-sett-tp01`" v-model="calSelectType">
      <label :for="`${compId}-rdo-sett-tp01`">{{ $t('comp.102') }}</label>
    </li>
    <li v-show="isTermBtnShow('02')" class="btn_rdo" @click="selOneWeek">
      <input type="radio" value="02" :id="`${compId}-rdo-sett-tp02`" v-model="calSelectType">
      <label :for="`${compId}-rdo-sett-tp02`">{{ $t('comp.103') }}</label>
    </li>
    <li v-show="isTermBtnShow('03')" class="btn_rdo" @click="selOneMonth">
      <input type="radio" value="03" :id="`${compId}-rdo-sett-tp03`" v-model="calSelectType">
      <label :for="`${compId}-rdo-sett-tp03`">{{ $t('comp.104') }}</label>
    </li>
    <li v-show="isTermBtnShow('04')" class="btn_rdo">
      <input type="radio" value="04" :id="`${compId}-rdo-sett-tp04`" v-model="calSelectType" @click="selMonth">
      <label :for="`${compId}-rdo-sett-tp04`">{{ $t('comp.105') }}</label>
      <div class="impp" v-show="showMp">
        <div ref="mp" class="imp"></div>
      </div>
    </li>
    <li v-show="isTermBtnShow('05')" class="btn_rdo" @click="selYear">
      <input type="radio" value="05" :id="`${compId}-rdo-sett-tp05`" v-model="calSelectType">
      <label :for="`${compId}-rdo-sett-tp05`">{{ $t('comp.106') }}</label>
    </li>
    <li v-show="isTermBtnShow('06')" class="btn_rdo" @click="selHalfYear">
      <input type="radio" value="06" :id="`${compId}-rdo-sett-tp06`" v-model="calSelectType">
      <label :for="`${compId}-rdo-sett-tp06`">{{ $t('comp.107') }}</label>
    </li>
  </ul>
</template>

<style scoped>
.input_calendar.mid, .input_calendar.mid input {
    width: 200px;
}
</style>

<script>
import moment from 'moment'

export default {
  name: 'EDateRangePickerForSettle',

  props: {
    title: { type: String, default: '', required: false },
    frDt: { type: String, default: '', required: false },
    toDt: { type: String, default: '', required: false },
    termType: {
      type: String,
      default: '02',
      required: false
    },
    showTermType: {
      type: Array,
      default: () => {
        return ['01', '02', '03', '04', '05', '06', '13']
      },
      require: false
    }
  },

  data () {
    return {
      compId: this.$uuid.v1(),
      sdate: this.frDt,
      edate: this.toDt,
      format: 'YYYY.MM.DD',
      inFormat: 'YYYYMMDD',
      calSelectType: '01',
      showTermTypeList: [],
      show: false,
      showMp: false
    }
  },
  computed: {
    formattedSdt: {
      set (v) {
        this.sdt = v.replace(/[^\d]/g, '')
      },

      get () {
        return this.sdt.replace(/(\d{4})(\d{1,2}(?=\d{2})|\d{1,2}(?=\d{1}))(\d{1,2}).*/, '$1.$2.$3')
      }
    },

    formattedEdt: {
      set (v) {
        this.edt = v.replace(/[^\d]/g, '')
      },

      get () {
        return this.edt.replace(/(\d{4})(\d{1,2}(?=\d{2})|\d{1,2}(?=\d{1}))(\d{1,2}).*/, '$1.$2.$3')
      }
    }
  },
  watch: {
    termType (nV, oV) {
      if (nV !== oV) {
        this.calSelectType = nV
        this.termTypeChange()
      }
    },

    showTermType (nV, oV) {
      if (nV !== oV) {
        this.showTermTypeList = nV
      }
    },

    sdate (nV, oV) {
      if (nV !== oV) {
        if (nV !== '') {
          $(this.$refs.startdp).data('daterangepicker').setStartDate(this.sdate)
          this.$emit('change', this.sdate.replaceAll('.', ''), this.edate.replaceAll('.', ''))
        }
      }
    },

    edate (nV, oV) {
      if (nV !== oV) {
        if (nV !== '') {
          $(this.$refs.startdp).data('daterangepicker').setEndDate(this.edate)
          this.$emit('change', this.sdate.replaceAll('.', ''), this.edate.replaceAll('.', ''))
        }
      }
    },

    showMp () {
      if (this.showMp) {
        this.$refs.mp.addEventListener('click', this.stopProp)
        document.body.addEventListener('click', this.selMonth)
      } else {
        this.$refs.mp.removeEventListener('click', this.stopProp)
        document.body.removeEventListener('click', this.selMonth)
      }
    },

    frdt () {
      this.sdate = this.frdt
      $(this.$refs.startdp).data('daterangepicker').setStartDate(this.sdate)
      this.$emit('change', this.sdate.replaceAll('.', ''), this.edate.replaceAll('.', ''))
    },

    todt () {
      this.edate = this.todt
      $(this.$refs.startdp).data('daterangepicker').setEndDate(this.edate)
      this.$emit('change', this.sdate.replaceAll('.', ''), this.edate.replaceAll('.', ''))
    }
  },

  created () {
    this.showTermTypeList = this.showTermType
    this.calSelectType = this.termType
    this.termTypeChange()
  },

  mounted () {
    $(this.$refs.startdp).daterangepicker({
      autoUpdateInput: false,
      showDropdowns: true,
      autoApply: true,
      locale: {
        format: this.format
      }
    }, (s, e, l) => {
      this.sdate = s.format(this.format)
      this.edate = e.format(this.format)
      this.emitChange()
    })
    .on('show.daterangepicker', () => {
      this.show = true
    })
    .on('hide.daterangepicker', () => {
      this.show = false
    })

    $(this.$refs.mp).MonthPicker({
      ShowIcon: false,
      MonthFormat: 'yy.mm',
      i18n: {
        year: this.$t('comp.009'),
        months: [
          `1${this.$t('comp.003')}`,
          `2${this.$t('comp.003')}`,
          `3${this.$t('comp.003')}`,
          `4${this.$t('comp.003')}`,
          `5${this.$t('comp.003')}`,
          `6${this.$t('comp.003')}`,
          `7${this.$t('comp.003')}`,
          `8${this.$t('comp.003')}`,
          `9${this.$t('comp.003')}`,
          `10${this.$t('comp.003')}`,
          `11${this.$t('comp.003')}`,
          `12${this.$t('comp.003')}`
        ]
      },
      OnAfterChooseMonth: (v) => {
        const m = moment(v)
        const symd = m.startOf('month').format(this.format)
        const eymd = m.endOf('month').format(this.format)

        this.setFromToDate(symd, eymd)
        this.showMp = false
      }
    })
},

  methods: {
    setFromToDate (sdt, edt) {
      this.sdate = sdt
      this.edate = edt
      this.emitChange()
    },
    emitChange () {
      this.$emit('change', this.sdate, this.edate)
    },
    setCal () {
      this.$refs.startdp.focus()
    },

    toggleRangePicker (e) {
      const startdp = $(this.$refs.startdp).data('daterangepicker')
      if (this.show) {
        startdp.hide()
      } else {
        startdp.show()
      }
    },

    termTypeChange () {
      if (this.calSelectType === '01') {
        // 직접입력 클릭시 Input 값이 변경되지 않도록 변경 막음.
        // this.directInput()
      } else if (this.calSelectType === '02') {
        this.selOneWeek()
      } else if (this.calSelectType === '03') {
        this.selOneMonth()
      } else if (this.calSelectType === '04') {
        this.selMonth()
      } else if (this.calSelectType === '05') {
        this.selYear()
      } else if (this.calSelectType === '06') {
        this.selHalfYear()
      } else if (this.calSelectType === '13') {
        // 3개월
        this.selQuarter()
      }
    },

    selRangePicker () {
      this.$refs.startdp.click()
    },

    directInput () {
      let now = moment()
      this.sdate = ''
      this.edate = ''
    },

    selOneWeek () {
      let now = moment()
      this.edate = now.format(this.format)
      this.sdate = now.add(-6, 'd').format(this.format)
    },

    selOneMonth () {
      let now = moment()
      this.edate = now.format(this.format)
      this.sdate = now.add(-1, 'M').add(1, 'd').format(this.format)
    },

    selMonth (e) {
      let now = moment()
      this.edate = now.format(this.format)
      this.sdate = now.set('date', 1).format(this.format)

      console.log('selMonth')
      console.log(this.showMp)

      e.stopPropagation()
      this.showMp = !this.showMp
    },
    stopProp (e) {
      e.stopPropagation()
    },
    selYear () {
      let now = moment()
      this.edate = now.format(this.format)
      this.sdate = now.add(-1, 'Y').add(1, 'd').format(this.format)
    },

    selHalfYear () {
      let now = moment()
      this.edate = now.format(this.format)
      this.sdate = now.add(-6, 'M').add(1, 'd').format(this.format)
    },

    selQuarter () {
      let now = moment()
      this.edate = now.format(this.format)
      this.sdate = now.add(-3, 'M').add(1, 'd').format(this.format)
    },

    isTermBtnShow (ty) {
      let rtn = false
      let tmp = this.showTermTypeList.filter(v => { return v === ty })
      if (tmp.length > 0) {
        rtn = true
      }
      return rtn
    }
  }
}
</script>
<style scoped>
.input_calendar.mid, .input_calendar.mid input {
    width: 200px;
}
div.input_error .inp_cal {
  border: 1px solid #f2084f !important;
}
.impp {
  position: absolute;
}
.imp {
  position: relative;
  top: 0px;
  left: -220px;
  z-index: 99999;
}
</style>
<style>
.month-picker-month-table .ui-button{
  width: 4.3em !important;
}
</style>
